<template>
    <div>
        <div v-if="!isBind">
            <div v-if="step===1||step===2">

                <div style="font-size: .4rem;margin:.3rem 0;text-align: center;position: relative">
                    <h3 style="font-size: .4rem;">
                        {{step===1?'请输入手机号':(step===2?'请输入验证码':'')}}
                    </h3>
                    <div v-if="step===2" style="position: absolute;top:0;right:15%;color: #2f3c94;font-size: .3rem;line-height:.9rem">
                        <van-icon v-if="!dialog.show_timer" name="replay" @click.prevent="handleGetVerification" />
                        <span href="" v-else>{{ dialog.timer }}</span>
                    </div>

                </div>

                <van-password-input
                        :value="value"
                        :mask="false"
                        :length="step===1?11:6"
                        :focused="showClip"
                        @focus="showClip = true"
                />
                <van-number-keyboard
                        v-model="value"
                        :show="showClip"
                        @input="onInput"
                        @delete="onDelete"
                />
            </div>
            <div v-else>
                <bind-phone :phone="this.user.phone" @bindSuccess="bindSuccess"></bind-phone>
            </div>
        </div>
        <div v-else style="font-size: .25rem;text-align: center;display: flex;align-items: center;justify-content: center;flex-direction: column;height:100vh">
            <h2>
                您已绑定
            </h2>
            <h3 style="margin: .3rem">
                姓名：{{user_info.name}}
            </h3>
            <h3>
                手机号：{{user_info.phone}}
            </h3>
            <div @click="changeAccount" style="text-decoration: underline;margin-top: 30px;">切换账号</div>
        </div>
    </div>
</template>

<script>
    import { NumberKeyboard,Toast } from 'vant';
    import BindPhone from "../BindPhone/BindPhone";
    export default {
        name: "login",
        data(){
            return{
                showClip:true,
                value:'',

                step:1,

                user:{},

                dialog:{
                    timer:60,
                    show_timer:false,
                    button_loading:false,
                },

                user_info:{},
                isBind:null
            }
        },
        components:{
            BindPhone
        },
        activated(){
            this.isBind=JSON.parse(localStorage.getItem('is_bind'))
            console.log(this.isBind)
        },
        mounted(){
            this.initInfo()
        },
        methods:{
            initInfo(){
                this.isBind=JSON.parse(localStorage.getItem('is_bind'))
                this.$axios.get('miniprogram/integralUser').then((res)=>{
                    if(res.data.code==='0000')
                    {
                        this.user_info=res.data.content.user;
                        this.$store.dispatch('getPhone',this.user_info.phone);
                        console.log(this.$store.state.phone)
                    }
                })
                this.value='';
            },
            onInput(value) {
                setTimeout(()=>{
                    if(this.value.length===11&&this.step===1)
                    {
                        this.$axios.get('wechatuser/isPhoneExist?phone='+this.value).then((res)=>{
                            if(res.data.code==='0000'){
                                this.user.phone=this.value;
                                this.value='';
                                if(res.data.content.is_exists){
                                    //直接输入验证码绑定
                                    this.handleGetVerification()

                                }else {
                                    //输入姓名，身份，验证码
                                    this.step=3;
                                }
                            }

                        })
                    }else if(this.value.length===6&&this.step===2){
                        this.handleBind()
                    }
                })
            },
            onDelete() {
                Toast(this.value);
            },
            handleGetVerification(){
                let TEL_REGEXP = /^1\d{10}$/;
                if (!this.user.phone) {
                    Toast('请输入手机号');
                } else if (!TEL_REGEXP.test(this.user.phone)) {
                    Toast('请输入正确手机号');
                } else {
                    this.$axios.post('miniprogram/sendVerificationCode', {
                        phone: this.user.phone,
                    }).then((res) => {
                        //成功发送
                        if (res.data.code === '0000') {
                            this.step=2;
                            // this.$Message.success('短信验证码已发送');
                            Toast('短信验证码已发送');
                            this.dialog.show_timer = true;
                            let st = setInterval(() => {

                                if (this.dialog.timer > 1) {
                                    this.dialog.timer--;
                                } else {
                                    clearInterval(st);
                                    st=null;
                                    this.dialog.show_timer = false;
                                    this.dialog.timer = 60;
                                }
                            }, 1000);
                        } else {
                            Toast(res.data.message)
                        }
                    }, (error) => {
                        Toast(error.response.data.content);
                        this.dialog.button_loading = false;
                    });
                }
            },
            handleBind(){
                this.$axios.post('wechatuser/bindUser', {
                    phone: this.user.phone,
                    captcha: this.value,
                }).then(res => {
                    if(res.data.code==='0000')
                    {
                        Toast('绑定成功！');
                        localStorage.setItem('is_bind',true);
                        this.initInfo();
                        this.step=3;
                    }else{
                        Toast('绑定失败，请重新绑定')
                    }
                })
            },
            bindUser(){

            },
            bindSuccess(){
                this.initInfo();
                this.step=3;
            },
            changeAccount(){
                this.step=1;
                this.isBind=false;
                console.log(this.step)
            }
        }
    }
</script>

<style scoped>

</style>
